import { useRef, useState } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { FlashMedTrials, TrialDuration, TrialEntitlement } from "@flashmed/trial-client";

function SmallLoading({ color = 'white' }) {
  return (
    <div className="spin-fast h-6 w-6">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="loading__indicator__graphic">
        <defs>
          <linearGradient x1="28.154%" y1="63.74%" x2="74.629%" y2="17.783%" id="blueLoadingIndicatorGradient">
            <stop stopColor={color} offset="0%"></stop>
            <stop stopColor="#FFF" stopOpacity="0" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g transform="translate(2)" fill="none" fillRule="evenodd">
          <circle stroke="url(#blueLoadingIndicatorGradient)" strokeWidth="3" cx="10" cy="12" r="10"></circle>
          <path d="M10 2C4.477 2 0 6.477 0 12" stroke={color} strokeWidth="3"></path>
          <rect fill={color} x="8" width="4" height="4" rx="8"></rect>
        </g>
      </svg>
    </div>
  )
}

const firebaseConfig = {
  apiKey: "AIzaSyByf1OMMKGcve4mDdIGuQAD-CTA1aH-7xo",
  authDomain: "flashmed-c4cbd.firebaseapp.com",
  databaseURL: "https://flashmed-c4cbd.firebaseio.com",
  projectId: "flashmed-c4cbd",
  storageBucket: "flashmed-c4cbd.appspot.com",
  messagingSenderId: "940176756553",
  appId: "1:940176756553:web:ae6ffe840c081187efd498",
  measurementId: "G-7KQPM5MHCY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

function App() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const loginHandler = async () => {
    setLoading(true);
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    setError(null);
    const auth = getAuth(app);
    signInWithEmailAndPassword(auth, email, password).then(async (user) => {
      const trials = new FlashMedTrials();
      const token = await user.user.getIdToken();
      const result = await trials.applyTrialToUser(token, TrialEntitlement.PRO, TrialDuration.THREE_DAY);
      setLoading(false);
      if (result.success) {
        setSuccess(true);
      } else {
        setError("No se puede activar en esta cuenta")
      }
    }).catch((error) => {
      setLoading(false);
      switch (error.code) {
        case 'auth/invalid-email': setError("Introduce un email válido"); break;
        case 'auth/wrong-password': setError("Contraseña incorrecta"); break;
        case 'auth/user-not-found': setError("No existe ningún usuario con este email"); break;
        case 'auth/user-disabled': setError("Usuario bloqueado temporalmente"); break;
        default: setError("No se puede activar en esta cuenta"); break;
      }
    });
  }

  if (success) return (
    <div className="flex flex-row gap-10 fixed inset-0 justify-center items-center">
      <div className="">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-24 w-24 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>
      <div style={{maxWidth: 700}} className="flex flex-col gap-1">
        <div className="font-medium text-3xl">Activada prueba de FlashMed Pro durante 3 días</div>
        <div className="font-light text-gray-400 text-xl">Para disfrutar de tu prueba de FlashMed Pro sólo tienes que entrar en la aplicación y hacer click en Menú &gt; Ajustes &gt; Restaurar compras</div>
      </div>
    </div>
  )

  return (
    <>
      <div className="flex flex-row fixed inset-0 justify-center items-center">
        <div style={{ width: 340 }}>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-4 items-center justify-center mb-6">
              <img alt="FlashMed Logo" style={{ width: '60px', height: '60px', borderRadius: 15 }} className="w-10 h-10" src={'https://cdn.flashmedapp.com/static/images/flashmed-logo@0.2x.png'} />
              <div>
                <h1 className="font-extrabold text-gray-700 text-4xl h-9">FlashMed</h1>
                <h2 className="text-gray-400 font-medium ml-0.5">Activar prueba gratuita</h2>
              </div>
            </div>
            <input autoComplete='email' id='current-email' ref={emailRef} type="text" placeholder="Email" className="bg-gray-100 rounded-lg py-2 px-3 outline-none text-gray-800" />
            <input autoComplete='current-password' id="current-password" ref={passwordRef} type="password" placeholder="Contraseña" className="bg-gray-100 rounded-lg py-2 px-3 outline-none text-gray-800" />
            {
              error
                ? <span className="text-red-500 text-sm">{error}</span>
                : null
            }
            <button style={{ backgroundColor: 'rgb(20,174,175)' }} className="mt-4 py-2 rounded-lg bg-blue-600 text-white flex flex-row justify-center" onClick={() => loginHandler()}>
              {
                loading
                  ? <SmallLoading />
                  : <span className="text-white font-medium">Activar</span>
              }
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
